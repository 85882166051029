import React from "react";
import NavBar from "../components/navBar";
import "../styles/main.sass";

function PaginaRegolamento() {
  return (
    <>
      <NavBar></NavBar>
      <main style={{ marginTop: 150 }} className="regolamento">
        <header>
          <h1>Regolamento del concorso in presenza</h1>
          <div
            style={{
              backgroundColor: "#c1272d",
              width: 150,
              height: 3,
              margin: "auto",
              marginBottom: 100,
            }}
          ></div>
        </header>
        <h2>Modalità d'iscrizione</h2>
        <p>
          Per iscriversi occorre compilare il{" "}
          <a href="https://concorsopaoloferro.edu.it/iscrizione-concorso-online">
            modulo online
          </a>{" "}
          entro il 10 Maggio 2024. Prima di compilare il modulo assicurarsi di
          effettuare il versamento e conservare la ricevuta in PDF da allegare
          dove richiesto.
          <br />
          <br /> - Se effettuato dalla scuola, Il versamento dovrà avvenire mediante accredito
          Tesoreria Provinciale dello Stato TAB A (Banca d’Italia) sul conto di
          tesoreria : 315569. Se effettuato da un genitore o da un docente dovra avvenire mediante bonifico bancario intestato a I.C. "Elio Vittorini" Scicli Iban IT38P0503684526T20220220002.
          <br />
          {/*  <br /> - Qualora effettuato da un genitore, il versamento dovrà essere
          effettuato mediante il pagamento di un bollettino PAGOPA, che verrà
          inviato dalla segreteria nell’e-mail indicata nel modulo di iscrizione
          Il termine ultimo per il pagamento è il 15 Maggio 2023. */}
        </p>
        <h2>Quote d'iscrizione</h2>
        <p>Sezione I: 12€ per ogni solista</p>
        <p>Sezione II: 13€ per ogni solista</p>
        <p>Sezione III: 8€ per ogni componente del gruppo</p>
        <p>Sezione IV: 6€ per ogni componente dell'orchestra</p>
        <h2>Sezioni e categorie</h2>
        <h3>Sezione I</h3>
        <p>Solisti Scuola Secondaria Primo Grado</p>
        <ul>
          <li>
            <span>Cat. A1:</span> Pianoforte prima media, programma di massimo 4
            minuti
          </li>
          <li>
            <span>Cat. A2:</span> Pianoforte seconda media, programma di massimo
            5 minuti
          </li>
          <li>
            <span>Cat. A3:</span> Pianoforte terza media, programma di massimo 6
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. B1:</span> Archi prima media, programma di massimo 4
            minuti
          </li>
          <li>
            <span>Cat. B2:</span> Archi seconda media, programma di massimo 5
            minuti
          </li>
          <li>
            <span>Cat. B3:</span> Archi terza media, programma di massimo 6
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. C1:</span> Fiati prima media, programma di massimo 4
            minuti
          </li>
          <li>
            <span>Cat. C2:</span> Fiati seconda media, programma di massimo 5
            minuti
          </li>
          <li>
            <span>Cat. C3:</span> Fiati terza media, programma di massimo 6
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. D1:</span> Chitarra prima media, programma di massimo 4
            minuti
          </li>
          <li>
            <span>Cat. D2:</span> Chitarra seconda media, programma di massimo 5
            minuti
          </li>
          <li>
            <span>Cat. D3:</span> Chitarra terza media, programma di massimo 6
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. E1:</span> Percussioni prima media, programma di massimo
            4 minuti
          </li>
          <li>
            <span>Cat. E2:</span> Percussioni seconda media, programma di
            massimo 5 minuti
          </li>
          <li>
            <span>Cat. E3:</span> Percussioni terza media, programma di massimo
            6 minuti
          </li>
          <div className="divisore"></div>
        </ul>
        <h3>Sezione II</h3>
        <p>Solisti Licei Musicali e non</p>
        <ul>
          <li>
            <span>Cat. A1:</span> Pianoforte Biennio, programma di massimo 6
            minuti
          </li>
          <li>
            <span>Cat. A2:</span> Pianoforte Triennio, programma di massimo 10
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. B1:</span> Archi Biennio, programma di massimo 6 minuti
          </li>
          <li>
            <span>Cat. B2:</span> Archi Triennio, programma di massimo 10 minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. C1:</span> Fiati Biennio, programma di massimo 6 minuti
          </li>
          <li>
            <span>Cat. C2:</span> Fiati Triennio, programma di massimo 10 minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. D1:</span> Chitarra Biennio, programma di massimo 6
            minuti
          </li>
          <li>
            <span>Cat. D2:</span> Chitarra Triennio, programma di massimo 10
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. E1:</span> Percussioni Biennio, programma di massimo 6
            minuti
          </li>
          <li>
            <span>Cat. E2:</span> Percussioni Triennio, programma di massimo 10
            minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. F1:</span> Canto Biennio, programma di massimo 6 minuti
          </li>
          <li>
            <span>Cat. F2:</span> Canto Triennio, programma di massimo 10 minuti
          </li>
          <div className="divisore"></div>
        </ul>
        <h3>Sezione III</h3>
        <p>
          Musica da Camera da 2 a 16 elementi – Scuole medie ad indirizzo
          musicale e non – Licei musicali e non.
        </p>
        <ul>
          <li>
            <span>Cat. A1:</span> Alunni prima media, qualsiasi formazione,
            programma di massimo 6 minuti
          </li>
          <li>
            <span>Cat. A2:</span> Alunni seconda media, qualsiasi formazione,
            programma di massimo 8 minuti
          </li>
          <li>
            <span>Cat. A3:</span> Alunni terza media, qualsiasi formazione,
            programma di massimo 10 minuti
          </li>
          <div className="divisore"></div>
          <li>
            <span>Cat. B1:</span> Alunni Liceo Biennio, qualsiasi formazione,
            programma di massimo 10 minuti
          </li>
          <li>
            <span>Cat. B2:</span> Alunni Liceo Triennio, qualsiasi formazione,
            programma di massimo 10 minuti
          </li>
          <div className="nota">
            * Nel caso di formazioni miste tra le varie classi fa riferimento la
            frequenza alla classe maggiore.
          </div>
        </ul>
        <h3>Sezione IV</h3>
        <p>
          Orchestre Scuole medie ad indirizzo musicale e non – Licei musicali e
          non
        </p>
        <ul>
          <li>
            <span>Cat. A:</span>Alunni scuola media, programma di massimo 15
            minuti
          </li>
          <li>
            <span>Cat. B:</span>Alunni scuole superiori, programma di massimo 15
            minuti
          </li>
          <div className="nota">
            * Possono partecipare ex alunni fino al triennio precedente.
          </div>
        </ul>
        <h2>Premi</h2>
        <p>
          A tutti i concorrenti verrà rilasciato un diploma e saranno assegnate
          inoltre le seguenti Borse di studio:
        </p>
        <ul>
          <li>
            Primo Premio Assoluto per le Sezioni I e II, 50 euro e una medaglia
          </li>
          <li>
            Primo Premio Assoluto per la Sezione III, 150 euro e una coppa
          </li>
          <li>Primo Premio Assoluto per la Sezione IV, 400 euro e una coppa</li>
        </ul>
        <p>A tal proposito si considerano:</p>
        <ul>
          <li>
            Primi Premi Assoluti i concorrenti che riporteranno la valutazione
            di 100/100
          </li>
          <li>
            Primi Premi i concorrenti che riporteranno la valutazione da 95 a 99
          </li>
          <li>
            Secondi Premi i concorrenti che riporteranno la valutazione da 90 a
            94
          </li>
          <li>
            Terzi Premi i concorrenti che riporteranno la valutazione da 85 a 89
          </li>
        </ul>
        <p>
          Le borse di studio saranno pagate tramite bonifico utilizzando l’IBAN
          inserito all’atto dell’iscrizione online.
        </p>
        <p>
          Alla scuola che si distinguerà per il maggior numero di Primi Premi
          Assoluti e Primi Premi tra tutte le Sezioni verrà assegnato un Diploma
          e una Borsa di studio di 200 euro.
        </p>
        <h2>Commissione</h2>
        <p>
          La commissione sarà composta dai Docenti di Strumento Musicale e di
          Musica dell’IC “E. Vittorini” di Scicli. Le decisioni della
          Commissione sono inappellabili e definitive.
        </p>
        <p>
          I risultati saranno resi noti alla fine di ogni singola giornata di
          audizioni. Saranno utilizzati i seguenti criteri di giudizio:
        </p>
        <ul>
          <li>Intonazione</li>
          <li>Interpretazione</li>
          <li>Competenze e capacità tecniche</li>
          <li>
            Livello difficoltà tecniche ed interpretative dei brani eseguiti
          </li>
        </ul>
        <h2>Strumenti</h2>
        <p>
          L’organizzazione del Concorso metterà a disposizione dei partecipanti
          il seguente materiale
        </p>
        <ul>
          <li>25 leggii professionali</li>
          <li>1 coppia di timpani</li>
          <li>Set di batteria completo</li>
          <li>3 pianoforti digitali</li>
          <li>6 supporti per tastiera</li>
          <li>service audio</li>
        </ul>
        <h2>Contatti</h2>
        <ul>
          <li>
            Prof. Diego Cristofaro (Direttore Artistico e Docente di Pianoforte
            dell’IC “E. Vittorini” di Scicli), tel. 3381076760
          </li>
        </ul>
        <div style={{ paddingBottom: 1 }}>
          <a href="/iscrizione-concorso-in-presenza">
            <button>Iscriviti al concorso in presenza</button>
          </a>
        </div>
      </main>
    </>
  );
}

export default PaginaRegolamento;

export const Head = () => (
  <>
    <title>Regolamento concorso in presenza</title>
    <meta
      name="description"
      content="Iscriviti al concorso musicale Paolo Ferro"
    />

    <meta property="og:url" content="https://concorsopaoloferro.edu.it/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Regolamento concorso in presenza" />
    <meta
      property="og:description"
      content="Iscriviti al concorso musicale Paolo Ferro"
    />
    <meta
      property="og:image"
      content="https://concorsopaoloferro.edu.it/cover.jpg"
    />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="concorsopaoloferro.edu.it" />
    <meta property="twitter:url" content="https://concorsopaoloferro.edu.it/" />
    <meta name="twitter:title" content="Regolamento concorso in presenza" />
    <meta
      name="twitter:description"
      content="Iscriviti al concorso musicale Paolo Ferro"
    />
    <meta
      name="twitter:image"
      content="https://concorsopaoloferro.edu.it/cover.jpg"
    />
    <link
      rel="canonical"
      href="https://concorsopaoloferro.edu.it/regolamento-concorso-in-presenza/"
    />
  </>
);
